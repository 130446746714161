import React from "react"
// import Img from "gatsby-image"
import { GatsbyImage } from "gatsby-plugin-image"
import { graphql } from "gatsby"

import Container from "react-bootstrap/Container"

import Layout from "../components/layout"
import SEO from "../components/seo"
// import ShareButtons from "../components/share-buttons"
import CTA from "../components/cta"

import "./article-post.scss"

const ArticleTemplate = ({ data }) => {
  const post = data && data.contentfulArticle
  const url =
    (typeof window !== "undefined" && window.location.href) ||
    `https://shapepowered.com/newsletter/${post.slug}`
  return (
    <Layout>
      <div style={{ background: "#fff" }}>
        <SEO
          title={post.title}
          meta={[
            {
              property: "og:title",
              content: post.title,
            },
            // {
            //   property: 'og:image',
            //   content: post.heroImage.src
            // },
            {
              property: "og:description",
              content: post.subtitle,
            },
            {
              property: "og:url",
              content: url,
            },
          ]}
        />
        <div className="hero-image">
          {/* <Img alt={post.title} fluid={post.heroImage.fluid} /> */}
          <GatsbyImage
            image={post.heroImage?.gatsbyImageData}
            alt={post.title}
          />
        </div>
        <Container className="content-wrapper">
          <h1 className="title">
            {post.title}
            <br />
            <small>{post.subtitle}</small>
          </h1>
          <div className="info-bar">
            <div className="date-type">
              {post.publishDate} <span className="dot">·</span>{" "}
              {post.type || "Blog"}
            </div>
            <div className="share-buttons">
              {/* <ShareButtons url={url} title={post.title} size={25} /> */}
            </div>
          </div>
          <div
            className="content"
            dangerouslySetInnerHTML={{
              __html: post.body.childMarkdownRemark.html,
            }}
          />
        </Container>
      </div>
    </Layout>
  )
}
export default ArticleTemplate

export const pageQuery = graphql`
  query ArticleBySlug($slug: String!) {
    contentfulArticle(slug: { eq: $slug }) {
      title
      tags
      subtitle
      publishDate(formatString: "MMMM D")
      heroImage {
        gatsbyImageData(layout: FULL_WIDTH)
      }
      slug
      body {
        childMarkdownRemark {
          html
        }
      }
    }
  }
`
